// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-bao-index-jsx": () => import("./../../../src/pages/bao/index.jsx" /* webpackChunkName: "component---src-pages-bao-index-jsx" */),
  "component---src-pages-bing-index-jsx": () => import("./../../../src/pages/bing/index.jsx" /* webpackChunkName: "component---src-pages-bing-index-jsx" */),
  "component---src-pages-boba-index-jsx": () => import("./../../../src/pages/boba/index.jsx" /* webpackChunkName: "component---src-pages-boba-index-jsx" */),
  "component---src-pages-bowl-index-jsx": () => import("./../../../src/pages/bowl/index.jsx" /* webpackChunkName: "component---src-pages-bowl-index-jsx" */),
  "component---src-pages-brand-index-jsx": () => import("./../../../src/pages/brand/index.jsx" /* webpackChunkName: "component---src-pages-brand-index-jsx" */),
  "component---src-pages-components-index-jsx": () => import("./../../../src/pages/components/index.jsx" /* webpackChunkName: "component---src-pages-components-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-team-index-jsx": () => import("./../../../src/pages/team/index.jsx" /* webpackChunkName: "component---src-pages-team-index-jsx" */)
}

